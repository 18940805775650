//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames  from 'classnames';
import I18n        from 'i18next';
import _           from 'lodash';
import { Tooltip } from 'react-tooltip';
import styled      from 'styled-components';

import PropTypes         from '@components/PropTypes';
import { svgColor }      from '@helper/StyledMixins';
import Icon              from '@stateless/atomic/Icon';
import IconType          from '@stateless/atomic/Icon/IconType';
import LabeledInputColor from '@stateless/atomic/LabeledInput/LabeledInputColor';
import LabeledInputType  from '@stateless/atomic/LabeledInput/LabeledInputType';
import TextInput         from '@stateless/atomic/TextInput';
import TextInputType     from '@stateless/atomic/TextInput/TextInputType';

// eslint-disable-next-line rulesdir/format-import-linter -- Required for the tooltip
import 'react-tooltip/dist/react-tooltip.css';

import styles from './styles.module.scss';

const propTypes = {
    bottomInfoText: PropTypes.string,
    color:          PropTypes.oneOfObjectValues(LabeledInputColor),
    disabled:       PropTypes.bool,
    error:          PropTypes.bool,
    highlight:      PropTypes.bool,
    infoText:       PropTypes.string,
    input:          PropTypes.children,
    label:          PropTypes.string,
    multiline:      PropTypes.bool,
    name:           PropTypes.string,
    onChange:       PropTypes.func,
    postIconType:   PropTypes.iconType,
    preIconType:    PropTypes.iconType,
    required:       PropTypes.bool,
    textInputType:  PropTypes.string,
    type:           PropTypes.oneOfObjectValues(LabeledInputType),
    value:          PropTypes.string,
    wrapperRef:     PropTypes.any,
};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const InfoTextWrapper = styled.div`
    svg
    {
        .fill
        {
            fill: ${(props) => props.theme.primaryColor};
        }

        .fillSecondary
        {
            fill: ${(props) => props.theme.textColor};
        }
    }
`;

const LabeledInputWrapper = styled.div`
    &.secondary
    {
        .preIconContainer
        {
            background: ${(props) => props.theme.secondaryColor};

            ${(props) => svgColor(props.theme.buttonTextColor)}
        }

        &.highlight
        {
            border-color: ${(props) => props.theme.secondaryColor};
        }
    }

    &.primary
    {
        .preIconContainer
        {
            background: ${(props) => props.theme.primaryColor};

            ${(props) => svgColor(props.theme.textColor)}
        }

        &.highlight
        {
            border-color: ${(props) => props.theme.primaryColor};
        }
    }

`;

const RequiredSpan = styled.span`
    color: ${(props) => props.theme.secondaryColor};
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const LabeledInput = ({
    bottomInfoText = null,
    color          = null,
    disabled       = false,
    error          = false,
    highlight      = false,
    infoText       = null,
    input          = null,
    label          = '',
    multiline      = false,
    name           = null,
    onChange       = null,
    postIconType   = null,
    preIconType    = null,
    required       = false,
    textInputType  = TextInputType.text,
    type           = LabeledInputType.default,
    value          = '',
    wrapperRef     = null,
}) => {
    const tooltipId = _.uniqueId('tooltip_');

    function renderPostIcon() {
        if (postIconType) {
            return (
                <div className={styles.postIconContainer}>
                    <Icon iconType={postIconType} />
                </div>
            );
        }

        return null;
    }

    function renderPreIcon() {
        if (preIconType) {
            return (
                <div className={'preIconContainer'}>
                    <Icon iconType={preIconType} />
                </div>
            );
        }

        return null;
    }

    function renderInfoText() {
        if (infoText) {
            return (
                <InfoTextWrapper
                    data-tooltip-id={tooltipId}
                    className={styles.labelInfoTextWrapper}
                >
                    <Icon iconType={IconType.information} />
                </InfoTextWrapper>
            );
        }

        return null;
    }

    function renderRequired() {
        if (required) {
            return (
                <RequiredSpan>
                    {I18n.t('format.requiredField')}
                </RequiredSpan>
            );
        }

        return null;
    }

    function renderInput() {
        if (input) {
            return input;
        }

        return (
            <TextInput
                color={color}
                disabled={disabled}
                multiline={multiline}
                name={name}
                onChange={onChange}
                type={textInputType}
                value={value}
            />
        );
    }

    return (
        <LabeledInputWrapper
            ref={wrapperRef}
            className={classNames(
                styles.labeledInputWrapper,
                {
                    'highlight': highlight,
                    'secondary': color === LabeledInputColor.secondary,
                    'primary':   color === LabeledInputColor.primary,
                },
            )}
        >
            {renderPreIcon()}
            <div
                className={classNames(
                    styles.labeledInput,
                    {
                        [styles.light]:             type === LabeledInputType.light,
                        [styles.lightWithoutLabel]: type === LabeledInputType.lightWithoutLabel,
                        [styles.error]:             error,
                    },
                )}
            >
                <div className={styles.labelWrapper}>
                    <label htmlFor={name}>
                        {label}
                        {renderRequired()}
                        {renderInfoText()}
                    </label>
                </div>
                <div
                    className={classNames(
                        styles.inputWrapper,
                        {
                            [styles.multiline]: multiline,
                        },
                    )}
                >
                    {renderInput()}
                    {renderPostIcon()}
                </div>
            </div>
            <Tooltip
                id={tooltipId}
                place={'top'}
                variant={'info'}
                content={infoText}
                className={styles.labelInfoText}
                disableStyleInjection={true}
            />
            {bottomInfoText && (
                <div className={styles.bottomInfoText}>
                    {bottomInfoText}
                </div>
            )}
        </LabeledInputWrapper>
    );
};

LabeledInput.propTypes = propTypes;

export default LabeledInput;
