//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _              from 'lodash';
import { useHistory } from 'react-router';
import styled         from 'styled-components';

import Routes               from '@constants/Routes';
import ColorHelper          from '@helper/Color';
import useContextTranslator from '@hooks/ContextTranslator';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import IconType             from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {};

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const WidgetWrapper = styled.div`
    background-color: ${(props) => props.theme.primaryColor} !important;
    border:           1px solid ${(props) => ColorHelper.getDarkerColor(props.theme.primaryColor, 2)} !important;
`;

const TextWrapper = styled.div`
    > h3,
    > h4,
    > p,
    > div > p
    {
        color: ${(props) => props.theme.textColor} !important;
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const FaqInfoWidget = () => {
    const translator = useContextTranslator('components.faqInfoWidget');
    const history    = useHistory();

    function onToFaqClicked() {
        const faqUrl = translator.t('faqUrl');

        window.open(faqUrl, '_blank');
    }

    function onToGuideClicked() {
        history.push(Routes.guide);
    }

    function renderTexts() {
        const texts = translator.t('texts', {
            returnObjects: true,
        });

        return _.map(texts, (text) => {
            return (
                <p>
                    {text}
                </p>
            );
        });
    }

    return (
        <WidgetWrapper
            className={styles.faqInfoWidget}
        >

            <div className={styles.contentWrapper}>
                <TextWrapper className={styles.textWrapper}>
                    <Headline
                        type={HeadlineType.headline3}
                        title={translator.t('title')}
                    />
                    <Headline
                        type={HeadlineType.headline4}
                        title={translator.t('subTitle')}
                    />
                    <div>
                        {renderTexts()}
                    </div>
                </TextWrapper>
                <div className={styles.buttonWrapper}>
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('toFaq')}
                        iconLeft={IconType.circleArrowRight}
                        onClick={onToFaqClicked}
                    />
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('toGuide')}
                        iconLeft={IconType.circleArrowRight}
                        onClick={onToGuideClicked}
                    />
                </div>
            </div>
        </WidgetWrapper>
    );
};

FaqInfoWidget.propTypes = propTypes;

export default FaqInfoWidget;
