//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    and:                      'und',
    more:                     'Mehr',
    no:                       'Nein',
    oclock:                   'Uhr',
    or:                       'oder',
    pageTitle:                'HALLO.IMMO',
    versionNumberDateFormat:  'DD.MM.YYYY HH:mm:ss',
    yes:                      'Ja',
    dashboard:                'Dashboard',
    forward:                  'Weiter',
    backward:                 'Zurück',
    save:                     'Speichern',
    downloadPdf:              'PDF herunterladen',
    sendEmail:                'E-Mail senden',
    newRequests:              'Neue Anfragen',
    availableTotalArea:       'Verfügbare Gesamtfläche',
    objects:                  'Objekte',
    email:                    'E-Mail',
    password:                 'Passwort',
    data:                     'Daten',
    purchasePrice:            'Kaufpreis',
    customerEditTitle:        'Kunde bearbeiten',
    rent:                     'Miete',
    unitEditTitle:            'Einheit bearbeiten',
    units:                    'Einheiten',
    edit:                     'Bearbeiten',
    download:                 'Herunterladen',
    delete:                   'Löschen',
    createUnit:               'Einheit anlegen',
    customers:                'Kunden',
    calculation:              'Berechnung',
    sumPurchasePrice:         'Summe Kaufpreis',
    calculations:             'Berechnungen',
    pageNotFound:             'Du hast eine Seite aufgerufen, die nicht oder nicht mehr existiert.',
    error_404:                'Seite nicht gefunden',
    copyRight:                '© Copyright {{year}} hallo.immo',
    guide:                    'Guide',
    sumKfz:                   'Summe KFZ',
    selectUnit:               'Einheit auswählen',
    selected:                 'Ausgewählt',
    pricePerSquareMeter:      'Preis pro m²',
    status:                   'Status',
    online:                   'Online',
    welcome:                  'Willkommen',
    loginText:                'Bitte melde dich mit deinen Zugangsdaten an.',
    number:                   'Nr.',
    rooms:                    'Zimmer',
    area:                     'Fläche',
    billwerkUrl:              'https://selfservice.billwerk.com/portal/account.html#/{{token}}',
    newUnit:                  'Neue Einheit anlegen',
    unit:                     'Einheit',
    kfz:                      'KFZ',
    perSquareMeter:           'pro m²',
    cold:                     'Kalt',
    phone:                    'Telefon',
    mobile:                   'Mobil',
    date:                     'Datum',
    object:                   'Objekt',
    yield:                    'Rendite',
    financePart:              'Finanzierungsbeitrag',
    ownCapital:               'Eigenkapital',
    ownCapitalYield:          'Eigenkapitalrendite',
    monthlyOutcome:           'Monatliches Ergebnis',
    unitNumber:               'Einheit-Nr.',
    buyingPrice:              'Kaufpreis',
    rentalYield:              'Mietrendite',
    profile:                  'Profil',
    resultMonthlyEffort:      'Ergebnis / Mtl. Aufwand',
    resultMonthlyProfit:      'Ergebnis / Mtl. Überschuss',
    showUnits:                'Einheiten anzeigen',
    objectScreenHeadline:     'Objekte & Einheiten',
    supportEmail:             'support@hallo.immo',
    objectScreenText:         'Hier kannst du deine Objekte und Einheiten verwalten (anlegen, bearbeiten, aktivieren/deaktivieren, löschen)',
    faqTitle:                 'FAQ',
    websiteUrl:               'https://hallo.immo',
    impressUrl:               'https://hallo.immo/impressum',
    privacyUrl:               'https://hallo.immo/datenschutz',
    selectCustomer:           'Person auswählen',
    noCustomers:              'Keine Person gefunden',
    settings:                 'Einstellungen',
    customerOnlineCalculator: 'Kunden-Online-Rechner',
    paymentData:              'Vertragsdaten',
    yup:                      {
        string: {
            // eslint-disable-next-line no-template-curly-in-string
            max: 'Dieses Feld darf höchstens ${max} Zeichen lang sein',
        },
    },
    modal:                    {
        close:                  'Schließen',
        cancel:                 'Abbrechen',
        confirm:                'Bestätigen',
        confirmDelete:          'Löschen bestätigen',
        sendMail:               'E-Mail senden',
        confirmCalculationSave: 'Speichern',
        cancelCalculation:      'Verwerfen',
    },
    format:                   {
        decimalSeparator:  ',',
        thousandSeparator: '.',
        spaceSuffix:       'qm',
        squareMeter:       'm²',
        priceSuffix:       '€',
        percentSuffix:     '%',
        roomSuffix:        ' Zimmer',
        requiredField:     ' *',
        interval:          'alle {{value}} Jahre',
        intervalInYears:   {
            prefix: 'alle ',
            suffix: ' Jahre',
        },
    },
    familyStatusOptions:      {
        single:  'ledig',
        married: 'verheiratet',
    },
    screens:                  {
        login:                          {
            pageTitle:        'Anmeldung',
            noAccount:        'Noch kein hallo.immo Profil?',
            signupNow:        'Kostenlos starten',
            passwordReset:    'Passwort vergessen?',
            passwordResetNow: 'Jetzt zurücksetzen',
        },
        passwordReset:                  {
            pageTitle:           'Passwort vergessen?',
            headline:            'Passwort vergessen?',
            subHeadline:         'Bitte gib deine E-Mail-Adresse ein. Informationen zum Zurücksetzen deines Passworts werden dir umgehend per E-Mail zugesandt.',
            noAccount:           'Noch kein hallo.immo Profil?',
            resetPasswordButton: 'Passwort zurücksetzen',
            alreadySignedUp:     'Schon registriert?',
            loginNow:            'Zum Login',
            signupNow:           'Kostenlos starten',
            email:               'E-Mail-Adresse',
            headlineChange:      'Passwort ändern',
            subHeadlineChange:   'Bitte gib dein neues Passwort ein.',
        },
        signup:                         {
            pageTitle:             'Registrierung',
            headline:              'hallo.immo 30 Tage kostenlos testen',
            subHeadline:           'Voller Funktionsumfang',
            featureHeadline:       'Garantiert ohne Risiko',
            feature1:              'Testphase endet automatisch',
            feature2:              'Keine Angabe von Zahlungsdaten nötig',
            feature3:              'Du gehst kein Vertragsrisiko ein',
            feature4:              'Keine versteckten Zusatzkosten',
            email:                 'E-Mail-Adresse',
            password:              'Passwort',
            passwordRepeat:        'Passwort wiederholen',
            firstname:             'Vorname',
            lastname:              'Nachname',
            signupButton:          'Jetzt registrieren',
            privacyPolicyUrl:      'https://hallo.immo/datenschutz',
            termsAndConditionsUrl: 'https://hallo.immo/nutzungsbedingungen',
            terms:                 'Ich habe die <0>Datenschutzhinweise</0> gelesen und stimme den <1>Nutzungsbeindungen</1> zu. *',
            alreadySignedUp:       'Schon registriert?',
            loginNow:              'Zum Login',
        },
        widgetScreen:                   {
            equity:       'Eigenkapital',
            interest:     'Zinsen',
            redemption:   'Tilgung',
            income:       'Einkommen',
            familyStatus: 'Familienstand',
            churchTax:    'Kirchensteuer',
        },
        dashboardScreen:                {
            welcomeText:   'Herzlich Willkommen {{userName}}',
            totalVolume:   'Gesamtvolumen',
            unitsOverview: 'Einheiten',
        },
        objectScreen:                   {
            headline:          'Objekte & Einheiten',
            text:              'Hier kannst du deine Objekte und Einheiten verwalten (anlegen, bearbeiten, aktivieren/deaktivieren, löschen)',
            newObject:         'Neues Objekt anlegen',
            showUnits:         'Einheiten anzeigen ({{count}})',
            availableUnits:    '{{availableUnits}}/{{totalUnits}} verfügbar',
            deleteUnitTitle:   'Einheit löschen?',
            deleteUnitText:    'Möchtest du die Einheit wirklich löschen? Diese Aktion kann nicht mehr rückgängig gemacht werden.',
            deleteObjectTitle: 'Objekt löschen?',
            deleteObjectText:  'Möchtest du das Objekt wirklich löschen? Diese Aktion kann nicht mehr rückgängig gemacht werden.',
        },
        editObjectScreen:               {
            pageEditTitle:          'Objekt bearbeiten',
            pageCreateTitle:        'Objekt erstellen',
            createTitle:            'Neues Objekt erstellen',
            editTitle:              'Objekt bearbeiten',
            objectAddress:          'Objektanschrift',
            buySideCosts:           'Kaufnebenkosten',
            taxSideData:            'Steuerliche Eckdaten',
            ownerCosts:             'Eigentümerkosten',
            houseManagement:        'Hausverwaltung',
            rentManagement:         'Mietverwaltung',
            reserves:               'Rücklagen',
            objectName:             'Bezeichnung des Objekts',
            streetAndNumber:        'Straße und Hausnummer',
            zipCode:                'Postleitzahl',
            city:                   'Ort',
            propertyTransferTax:    'Grunderwerbsteuer',
            notaryCosts:            'Notarkosten',
            notaryCostsInfoText:    'inkl. Grundbuchkosten',
            mediationCosts:         'Vermittlungskosten',
            mediationCostsInfoText: 'inkl. MwSt.',
            groundAndBuilding:      'Grund- und Bodenanteil',
            createObject:           'Objekt anlegen',
            saveObject:             'Objekt speichern',
            taxInfoText:            'inkl. MwSt. monatlich',
            reserveInfoText:        'pro Quadratmeter und Monat',
        },
        editUnitScreen:                 {
            pageEditTitle:           'Einheit bearbeiten',
            pageCreateTitle:         'Einheit erstellen',
            saveUnit:                'Einheit speichern',
            editUnit:                'Einheit bearbeiten',
            createUnit:              'Einheit erstellen',
            createNewUnit:           'Neue Einheit erstellen',
            unitLabel:               'Einheit',
            calculationData:         'Berechnungsdaten',
            kfzPlaces:               'KFZ-Stellplätze',
            apartmentNumber:         'Wohnungsnummer',
            unitLocationInObject:    'Lage der Wohnung im Objekt',
            spaceInM2:               'Fläche in m²',
            numberOfRooms:           'Anzahl der Zimmer',
            status:                  'Status',
            showUnitInCalculator:    'Einheit im Rechner anzeigen',
            buyingPrice:             'Kaufpreis',
            monthlyRent:             'Miete pro Monat',
            showUnitInCalculatorYes: 'Ja',
            showUnitInCalculatorNo:  'Nein',
        },
        guideScreen:                    {
            headline:               'So startest du mit hallo.immo',
            subTitle:               'Erfahre Schritt für Schritt, wie hallo.immo funktioniert',
            setupProfile:           'Profil einrichten',
            setupProfileText:       'Klicke rechts oben auf deinen Namen und gehe zum <0>Profil</0>. Hinterlege deine Kontaktdaten und dein Logo. Klicke abschließend Speichern',
            setupSettings:          'Einstellungen',
            setupSettingsText:      'Klicke <0>Einstellungen</0> und definiere die Standardwerte für deinen Rechner (Zins, Tilgung, z.v.E., alle Steigerungen und Intervalle). Klicke abschließend Speichern',
            createObject:           'Ein Objekt anlegen',
            createObjectText:       'Klicke links im Menü <0>Objekte</0> und anschließend Neues Objekt anlegen. Hinterlege alle objektbezogenen Daten, u.a. Bezeichnung, Adresse, Kaufnebenkosten, Eigentümerkosten und Grund- und Bodenanteil und klicke abschließend Objekt erstellen',
            createUnit:             'Eine Einheit anlegen',
            createUnitText:         'Gehe zum Objekt und klicke Neue Einheit anlegen. Hinterlege alle zur Wohnung dazugehörigen Informationen, u.a. Wohnungsnummer, Lage der Wohnung, Fläche, Anzahl Zimmer, Kaufpreis, Miete pro Monat, KFZ-Stellplatz und klicke abschließend Einheit erstellen.',
            createCustomer:         'Einen Kunden anlegen',
            createCustomerText:     'Klicke links im Menü <0>Kunden</0> und anschließend Neuen Kunden anlegen. Trage alle kundenbezogenen Daten, u.a. Name, Adresse, Telefonnummer, z.v.E., Kirchensteuer, Familienstand, Einkommensteigerung und Einkommensteigerungsintervall ein und klicke abschließend Kunde anlegen.',
            executeCalculation:     'Eine Berechnung durchführen',
            executeCalculationText: 'Jetzt bist du bereit deine 1. Berechnung mit hallo.immo durchzuführen. Klicke links im Menü <0>Berechnung</0>, wähle in Schritt 1 eine Person aus, klicke weiter, um zu Schritt 2 zu gelangen und eine Wohneinheit auszuwählen. In Schritt 3 erhältst du eine Übersicht der Finanzierung. Hier kannst du das Eigenkapital, Zins und Tilgung anpassen. Klicke weiter und erhalte das Ergebnis deiner Berechnung inkl. übersichtlicher Diagramme zum Cashflow und der Vermögensbetrachtung. Hier kannst du die Steigerungen und Intervalle zum Einkommen, zur Miete, zu den Betriebskosten und dem Objektwert anpassen bis du das gewünschte Ergebnis erzielt hast. Speichere das Ergebnis damit es beim Kunden abgelegt wird, lade es als PDF herunter oder versende es direkt per E-Mailanhang an deinen Kunden.',
            faqText:                'In unserem <0>FAQ-Bereich</0> findest du weitere Informationen',
            contactText:            'Du hast Fragen? Dann schreib uns gerne eine E-Mail an <0>support@hallo.immo</0> oder ruf uns an 07542 - 55 98 455 (Mo-Do, 9-17 Uhr).',
            tutorialVideo1:         'Erklärvideo 1/2',
            tutorialVideo2:         'Erklärvideo 2/2',
        },
        profileScreen:                  {
            headline:         'Profil',
            userData:         'Benutzerdaten',
            text:             'Im Profil bearbeitest du deine persönlichen Daten, die in der Investitionsberechnung (PDF) angezeigt werden.',
            company:          'Firma',
            firstName:        'Vorname',
            lastName:         'Nachname',
            street:           'Straße',
            houseNumber:      'Hausnummer',
            zipCode:          'PLZ',
            city:             'Ort',
            phone:            'Telefon',
            mobile:           'Mobil-Nummer',
            website:          'Website',
            password:         'Passwort',
            passwordConfirm:  'Passwort wiederholen',
            saveUserData:     'Benutzer speichern',
            changePassword:   'Passwort ändern',
            dropFilesOrClick: 'Dateien hier ablegen oder klicken',
            profilePicture:   'Profilbild',
            removePicture:    'Bild entfernen',
        },
        settingsScreen:                 {
            settings:                        'Einstellungen',
            headline:                        'Einstellungen',
            text:                            'Bitte definiere deine Einstellungen für die Berechnungen. Diese bilden die Grundlage für den Rechner auf deiner Webseite und die Berechnungen hier im Portal.',
            interest:                        'Zins',
            redemption:                      'Tilgung',
            toBeTaxedYearlyIncome:           'Zu versteuerndes Jahreseinkommen',
            increaseIncomeInPercent:         'Einkommenssteigerung in Prozent',
            increaseIncomeIntervalInYears:   'Einkommenssteigerungsintervall in Jahren',
            increaseRentInPercent:           'Mietsteigerung in Prozent',
            increaseRentIntervalInYears:     'Mietsteigerungsintervall in Jahren',
            increaseOperatingCostsInPercent: 'Betriebskostensteigerung in Prozent',
            increaseOperatingCostsInterval:  'Betriebskostensteigerungsintervall in Jahren',
            increaseObjectValueInPercent:    'Wertsteigerung in Prozent',
            increaseObjectValueInterval:     'Wertsteigerungsintervall in Jahren',
            saveSettings:                    'Einstellungen speichern',
        },
        calculationScreen:              {
            calculationCustomerTitle:     '1 von 4: Personenauswahl',
            calculationObjectTitle:       '2 von 4: Einheit auswählen',
            calculationFinanceTitle:      '3 von 4: Finanzierung',
            calculationResultTitle:       '4 von 4: Ergebnis',
            rentPhase:                    'Vermietungsphase',
            selectPerson:                 'Person auswählen',
            noOptionsMessage:             'Du hast noch keine Personen angelegt.',
            customerPerson1:              'Person 1',
            customerPerson2:              'Person 2',
            taxesAndFinance:              'Steuern & Finanzen',
            name:                         'Name',
            firstname:                    'Vorname',
            email:                        'E-Mail',
            mobile:                       'Mobil',
            phone:                        'Tel.-Nr. Privat',
            phoneBusiness:                'Tel.-Nr. Geschäftlich',
            maritalStatus:                'Familienstand',
            churchTax:                    'Kirchensteuer',
            sumPurchasePrice:             'Summe Kaufpreis',
            yearlyTaxableIncome:          'Jährliches zu versteuerndes Einkommen',
            incomeRaise:                  'Einkommenssteigerung %',
            incomeRaiseInterval:          'Einkommenssteigerungsintervall',
            investPhase:                  'Investitionsphase',
            buyingPriceLivingEntity:      'Kaufpreis Wohneinheit',
            buyingPriceKfz:               'Kaufpreis KFZ Setllplatz',
            sumBuyingPrice:               'Summe Kaufpreis',
            notaryCosts:                  'Notarkosten',
            propertyTransferTax:          'Grunderwerbsteuer',
            mediationCosts:               'Vermittlungskosten',
            other:                        'Sonstiges',
            buyingSideCosts:              'Kaufnebenkosten',
            selfCapital:                  'Eigenkapital',
            financeSum:                   'Finanzierungsbeitrag',
            rentManagement:               'Mietverwaltung',
            interest:                     'Zins',
            houseManagement:              'Hausverwaltung',
            redemption:                   'Tilgung',
            monthlyPayments:              'Ausgaben mtl',
            monthlyIncome:                'Einnahmen mtl',
            summary:                      'Zusammenfassung',
            maintenance:                  'Instandhaltung',
            sumPayments:                  'Summe Ausgaben',
            taxEffect:                    'Steuereffekt',
            sumIncome:                    'Summe Einnahmen',
            purchasePrice:                'Kaufpreis',
            buySideCosts:                 'Kaufnebenkosten',
            rentalYield:                  'Mietrendite',
            ownCapitalYield:              'Eigenkapitalrendite',
            rent:                         'Miete',
            street:                       'Straße',
            houseNumber:                  'Hausnummer',
            postalCode:                   'PLZ',
            city:                         'Ort',
            raiseAndIntervals:            'Steigerung und Intervalle',
            incomeRaiseInPercent:         'Einkommenssteigerung in %',
            incomeRaiseIntervalInYears:   'Einkommenssteigerungsintervall in Jahren',
            rentRaiseInPercent:           'Mietsteigerung in %',
            rentRaiseIntervalInYears:     'Mietsteigerungsintervall in Jahren',
            operatingCostsRaiseInPercent: 'Betriebskostensteigerung in %',
            operatingCostsRaiseInterval:  'Betriebskostensteigerungsintervall in Jahren',
            objectValueRaiseInPercent:    'Wertsteigerung in %',
            objectValueRaiseInterval:     'Wertsteigerungsintervall in Jahren',
            assetAnalysis:                'Vermögensbetrachtung',
            cashflowDevelopment:          'Entwicklung Cashflow',
            financingDevelopment:         'Entwicklung jährliche Finanzierungskosten',
            emailSubject:                 'Deine Berechnung von {{name}}',
            sendMailTitle:                'Berechnung per E-Mail senden',
            sendMailText:                 'Bitte prüfe die E-Mail-Adresse des Kunden und klicke auf "E-Mail senden", um die Berechnung zu schicken.',
            saveConfirm:                  {
                title: 'Berechnung speichern?',
                text:  'Möchtest du die Berechnung speichern? Die Berechnung wird dem Kunden zugeordnet und kann später bearbeitet werden und als PDF heruntergeladen werden.',
            },
        },
        customersScreen:                {
            headline:            'Kunden',
            text:                'Hier kannst du deine Kunden verwalten (neue Kunden anlegen, bearbeiten, löschen)',
            new:                 'Neuen Kunden anlegen',
            newCalculation:      'Neue Berechnung',
            pageEditTitle:       'Kunde bearbeiten',
            pageCreateTitle:     'Kunde erstellen',
            createTitle:         'Neuen Kunde erstellen',
            editTitle:           'Kunde bearbeiten',
            showCalculations:    'Berechnungen anzeigen',
            editCustomer:        'Kunde bearbeiten',
            createCustomer:      'Neuen Kunde anlegen',
            name:                'Name',
            firstname:           'Vorname',
            street:              'Straße',
            houseNumber:         'Hausnummer',
            postalCode:          'PLZ',
            city:                'Ort',
            email:               'E-Mail',
            phone:               'Telefon',
            phoneBusiness:       'Telefon Geschäftlich',
            mobile:              'Mobil',
            yes:                 'Ja',
            no:                  'Nein',
            fullName:            '{{firstname}} {{lastname}}',
            saveCustomer:        'Kunde speichern',
            deleteCustomerTitle: 'Kunde löschen?',
            deleteCustomerText:  'Möchtest du den Kunden wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
        },
        paymentDataScreen:              {
            contractData:             'Vertragsdaten',
            contractStatus:           'Vertragsstatus',
            nextBillingDate:          'Die nächste Abrechnung findet am  <strong>{{date}}</strong> um {{time}} Uhr statt.',
            nextBillingDateTrial:     'Deine Testphase läuft noch bis <strong>{{date}}</strong> um {{time}} Uhr. Bis dahin nutzt du hallo.immo 30 Tage kostenfrei und vollumfänglich.',
            nextBillingDateCancelled: 'Dein Vertrag endet am  <strong>{{date}}</strong> um {{time}} Uhr.',
            management:               'Verwaltung',
            managementText:           'Im Portal unseres Zahlungsanbieters kannst du deine Rechnungs- und Zahlungsdaten einsehen und bearbeiten sowie deine Rechnungen herunterladen.',
            manageContract:           'Vertragsdaten verwalten',
            subscriptionOption:       {
                buttonLabel:           'Jetzt buchen',
                taxInformationMonthly: 'monatliche Zahlung zzgl. MwSt.',
                taxInformationYearly:  'jährliche Zahlung zzgl. MwSt.',
                titleYearly:           'Jahrespaket',
                titleMonthly:          'Monatspaket',
                priceMonthly:          '{{price}} / Monat',
                headline:              'hallo.immo Pakete',
                infoText:              'Wähle dein bevorzugtes hallo.immo Paket. Klicke dazu auf eins der angebotenen Einzelplatz-Pakete. Im Anschluss wirst du zum Zahlungsanbieter, der für den Bestellprozess zuständig ist, weitergeleitet.',
                trialPhaseInformation: 'Bitte beachte, dass wenn du vor Ablauf der Testphase ein kostenpflichtiges Paket abschließt, die Restlaufzeit der Testphase nicht gutgeschrieben wird bzw. auf das Paket angerechnet wird.',
            },
        },
        customerOnlineCalculatorScreen: {
            customerOnlineCalculator: 'Kunden-Online-Rechner',
            mainColor:                'Hauptfarbe',
            detailColor:              'Akzentfarbe',
            backgroundColor:          'Hintergrundfarbe',
            textColor:                'Textfarbe',
            colorSettings:            'Farbeinstellungen',
            embedCodeHeader:          'Code zur Einbettung auf deiner Website',
            howToPublishHeader:       'So veröffentlichst du den Rechner',
            howToPublishText:         'Du kannst den hallo.immo Rechner als HTML-Code auf deiner Webseite einbinden und mit deinen Wohneinheiten veröffentlichen. Gehe dazu zur entsprechenden Einheit, die du veröffentlichen möchtest, und klicken im Feld Einheit im Rechner anzeigen auf JA. Der HTML-Code wird nicht von Ad-Blockern unterdrückt.',
            calculatorPreview:        'Anzeige des Online-Rechners auf deiner Webseite',
        },
    },
    unitStatus:               {
        available: 'Frei',
        reserved:  'Reserviert',
        sold:      'Verkauft',
        online:    'Wird im Online-Rechner angezeigt',
        offline:   'Wird nicht im Online-Rechner angezeigt',
    },
    notifications:            {
        pleaseStartTrial:               'Bitte starte deine Testphase, um hallo.immo weiterhin nutzen zu können.',
        setNewPassword:                 {
            success: 'Passwort erfolgreich geändert.',
            error:   'Passwort konnte nicht geändert werden. Bitte versuche es später erneut.',
        },
        passwordReset:                  {
            success: 'E-Mail zum Zurücksetzen des Passworts wurde versendet.',
            error:   'Passwort konnte nicht zurückgesetzt werden. Bitte versuche es später erneut.',
        },
        userDisabled:                   'Bitte bestätige deine E-Mail-Adresse, um dich einloggen zu können.',
        emailConfirmed:                 'E-Mail-Adresse bestätigt. Du kannst dich jetzt einloggen.',
        signupFailed:                   'Registrierung fehlgeschlagen. Bitte versuche es erneut.',
        signupSucceeded:                'Registrierung erfolgreich. Bitte überprüfe deine E-Mails, um deine E-Mail-Adresse zu bestätigen.',
        signupTermsError:               'Bitte akzeptiere die Nutzungsbedingungen und Datenschutzbestimmungen.',
        signupEmailError:               'Bitte geben eine gültige E-Mail-Adresse an.',
        signupPasswordError:            'Das Passwort muss mindestens 8 Zeichen lang sein und',
        signupPasswordRepeatError:      'Die Passwörter stimmen nicht überein.',
        signupFirstnameError:           'Bitte geben deinen Vornamen an.',
        signupLastnameError:            'Bitte geben deinen Nachnamen an.',
        trialExpired:                   'Deine Testphase ist abgelaufen. Bitte buche ein Paket, um hallo.immo weiterhin nutzen zu können.',
        orderResponse:                  'Glückwunsch zu deinem neuen Immobilien-Rechner.',
        paymentFailed:                  'Upps, da ist etwas schief gelaufen. Bitte versuche es erneut.',
        calculationMailValidationError: 'Bitte fülle alle Pflichtfelder korrekt aus. Deine E-Mail-Adresse ist nicht gültig.',
        calculationEmailMissing:        'Bitte hinterlege dem Kunden eine E-Mail-Adresse.',
        calculationUnitMissing:         'Bitte wähle zuerst eine Einheit aus.',
        calculationCustomerMissing:     'Bitte wähle zuerst eine Person aus.',
        deleteCalculation:              {
            success: 'Berechnung erfolgreich gelöscht.',
            error:   'Berechnung konnte nicht gelöscht werden. Bitte versuche es später erneut.',
        },
        fetchCalculation:               {
            success: 'Berechnung erfolgreich geladen.',
            error:   'Berechnung konnte nicht geladen werden. Bitte versuche es später erneut.',
        },
        sendCalculationMail:            {
            success: 'E-Mail erfolgreich versendet.',
            error:   'E-Mail konnte nicht versendet werden. Bitte versuche es später erneut.',
        },
        widgetContactForm:              {
            success:         'Deine Anfrage wurde erfolgreich versendet.',
            error:           'Deine Anfrage konnte nicht versendet werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        deleteUnit:                     {
            success: 'Einheit erfolgreich gelöscht.',
            error:   'Einheit konnte nicht gelöscht werden. Bitte versuche es später erneut.',
        },
        deleteCustomer:                 {
            success: 'Kunde erfolgreich gelöscht.',
            error:   'Kunde konnte nicht gelöscht werden. Bitte versuche es später erneut.',
        },
        updateObject:                   {
            success:         'Objekt erfolgreich aktualisiert.',
            error:           'Objekt konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        deleteObject:                   {
            success: 'Objekt erfolgreich gelöscht.',
            error:   'Objekt konnte nicht gelöscht werden. Bitte versuche es später erneut.',
        },
        createObject:                   {
            success:         'Objekt erfolgreich erstellt.',
            error:           'Objekt konnte nicht erstellt werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        dashboard:                      {
            fetchFailed: 'Dashboard konnte nicht geladen werden. Bitte versuche es später erneut.',
        },
        updateColorSettings:            {
            success:         'Kunden-Rechner erfolgreich aktualisiert.',
            error:           'Kunden-Rechner konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        createColorSettings:            {
            success:         'Kunden-Rechner erfolgreich erstellt.',
            error:           'Kunden-Rechner konnte nicht erstellt werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        createCalculation:              {
            success:         'Berechnung erfolgreich erstellt.',
            error:           'Berechnung konnte nicht erstellt werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        downloadCalculationPDF:         {
            success: 'PDF erfolgreich heruntergeladen.',
        },
        saveSettings:                   {
            success: 'Einstellungen erfolgreich gespeichert.',
            error:   'Einstellungen konnten nicht gespeichert werden. Bitte versuche es später erneut.',
        },
        updateCalculation:              {
            success:         'Berechnung erfolgreich aktualisiert.',
            error:           'Berechnung konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        changePassword:                 {
            success:         'Passwort erfolgreich geändert.',
            error:           'Passwort konnte nicht geändert werden. Bitte versuche es später erneut.',
            validationError: 'Die Passwörter müssen übereinstimmen und mindestens {{minimumLength}} Zeichen lang sein.',
        },
        updateCustomer:                 {
            success:         'Kunde erfolgreich aktualisiert.',
            error:           'Kunde konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        createCustomer:                 {
            success:         'Kunde erfolgreich erstellt.',
            error:           'Kunde konnte nicht erstellt werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        updateUnit:                     {
            success:         'Einheit erfolgreich aktualisiert.',
            error:           'Einheit konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        createUnit:                     {
            success:         'Einheit erfolgreich erstellt.',
            error:           'Einheit konnte nicht erstellt werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        updateUser:                     {
            success:         'Profil erfolgreich aktualisiert.',
            error:           'Profil konnte nicht aktualisiert werden. Bitte versuche es später erneut.',
            validationError: 'Bitte fülle alle Pflichtfelder korrekt aus.',
        },
        fetchObjects:                   {
            error: 'Objekte konnten nicht geladen werden. Bitte versuche es später erneut.',
        },
        fetchUser:                      {
            error: 'Nutzer konnte nicht geladen werden. Bitte versuche es später erneut.',
        },
        fetchCustomers:                 {
            error: 'Kunden konnten nicht geladen werden. Bitte versuche es später erneut.',
        },
        imageTooLarge:                  'Das Bild ist zu groß. Bitte wähle ein Bild mit maximal {{imageSize}}.',
        error:                          'Fehler',
        info:                           'Info',
        success:                        'Erfolg',
        warning:                        'Warnung',
    },
    components:               {
        trialBanner:       {
            headline: 'Starte jetzt deine kostenlose 30-tägige Testphase!',
            text:     'Hole dir einen 30-tägigen Testzugang und überzeuge dich selbst vom smarten Immobilien-Rechner.',
            button:   'Kostenlos testen',
        },
        spaceLabel:        {
            suffix: 'qm',
        },
        currencyLabel:     {
            format: '{{value}}',
        },
        widgetUnit:        {
            spaceAndRoomSeparator: ' | ',
        },
        widgetResult:      {
            download:          'Detaillierte Berechnung herunterladen',
            monthlyEffort:     'Monatlicher Aufwand',
            monthlySurplus:    'Monatlicher Überschuss',
            poweredBy:         'powered by ',
            poweredByLink:     'https://hallo.immo/',
            poweredByLinkName: 'hallo.immo',
        },
        widgetContactForm: {
            headline:              'Jetzt ihre persönliche Berechnung anfordern',
            text:                  'Fordern Sie hier die detaillierten Ergebnisse der Investitionsberechnung kostenlos und unverbindlich an.\n Dazu müssen Sie lediglich die unten stehenden Angaben ergänzen und auf "PDF anfordern" klicken.',
            thankYouHeadline:      'Vielen Dank für Ihr Interesse an unserem Immobilienangebot.',
            thankYouText:          'Ihre persönliche Investitionsberechnung erhalten Sie in wenigen Minuten als PDF-Datei direkt in Ihr E-Mail-Postfach.',
            firstname:             'Vorname',
            lastname:              'Nachname',
            email:                 'Bitte E-Mail eingeben *',
            street:                'Straße',
            postalCode:            'PLZ',
            houseNumber:           'Hausnummer',
            city:                  'Wohnort',
            phoneNumber:           'Telefon',
            submit:                'Berechnung anfordern',
            privacyPolicyUrl:      'https://hallo.immo/datenschutz',
            termsAndConditionsUrl: 'https://hallo.immo/nutzungsbedingungen',
            terms:                 'Ich habe die <0>Datenschutzhinweise</0> gelesen und stimme den <1>Nutzungsbeindungen</1> zu. *',
        },
        breadCrumbBar:     {
            customer: 'Person',
            object:   'Wohneinheit',
            finance:  'Finanzierung',
            result:   'Ergebnis',
        },
        profileMenu:       {
            profile:                  'Profil',
            settings:                 'Einstellungen',
            customerOnlineCalculator: 'Kunden-Online-Rechner',
            paymentData:              'Vertragsdaten',
            logout:                   'Logout',
        },
        faqInfoWidget:     {
            title:    'FAQ',
            subTitle: 'Wie können wir dir weiterhelfen?',
            texts:    [
                'Du suchst nach einem bestimmten Thema?',
                'Nutze die Suche für die häufig gestellten Fragen.',
            ],
            toFaq:    'Zur den FAQ',
            toGuide:  'Zum Guide',
            faqUrl:   'https://hallo.immo/service/haeufige-fragen',
        },
        parkingSpaceList:  {
            type:               'Art',
            buyingPrice:        'Kaufpreis',
            rentingPrice:       'Mietpreis pro Monat',
            noParkingSpaces:    'Keine Stellplätze vorhanden',
            addParkingSpace:    'Stellplatz hinzufügen',
            garage:             'Garage',
            parkingSpace:       'Stellplatz',
            carport:            'Carport',
            doubleParker:       'Doppelparker',
            undergroundCarPark: 'Tiefgarage',
        },
    },
    // TODO-Skeleton start
    //
    // BEWARE: Do NOT add any entry here. If you want to add a new entry,
    // please add it above the "TODO-Skeleton start" comment since
    // otherwise it is not clear which language keys are actually
    // used in this app.
    //
    // Also move up any key you use.
    alertBoxErrorText:             'Fehler',
    alertBoxErrorTextTranslated:   'Fehler (automatisch übersetzt)',
    alertBoxSuccessText:           'Erfolg',
    alertBoxSuccessTextTranslated: 'Erfolg (automatisch übersetzt)',
    autoLogin:                     'Auto Login',
    back:                          'Zurück',
    bookmarkBannerText:            'Tipp: Fügen Sie diese Anwendung als Lesezeichen hinzu, um sie offline verwenden zu können.',
    countDownDay:                  'Tag',
    countDownDay_plural:           'Tage',
    countDownHour:                 'Stunde',
    countDownHours:                'Stunden',
    messageDateFormat:             'DD.MM.YYYY HH:mm',
    countDownMinute:               'Minute',
    countDownMinutes:              'Minuten',
    countDownSecond:               'Sekunde',
    countDownSeconds:              'Sekunden',
    decimalSeparator:              ',',
    euroSign:                      '€',
    example404:                    'Beispiel-404',
    exampleAlertBox:               'Beispiel-Alertbox',
    exampleAlertBoxAll:            'Alle Alertboxen',
    exampleAlertBoxSuccess:        'Alle Erfolg-Alertboxen',
    exampleBreadCrumbBar:          'Beispiel-Breadcrumbbar',
    exampleButtons:                'Beispiel-Buttons',
    exampleCheckBox:               'Beispiel-Checkbox',
    exampleCarousel:               'Beispiel-Karussell',
    exampleConsole:                'Beispiel-Konsole',
    exampleCountDown:              'Beispiel-Countdown',
    exampleDateTimeFormat:         'DD.MM.YYYY HH:mm:ss',
    exampleDates:                  'Beispiel-Datum',
    exampleDatesBrowserLanguage:   'Beispiel-Datum Browser-Sprache',
    exampleDatesDE:                'Beispiel-Datum DE',
    exampleDatesEN:                'Beispiel-Datum EN',
    exampleDebounceInput:          'Beispiel-Entprelltes-Eingabefeld',
    exampleDropdown:               'Beispiel-Dropdown',
    exampleIcons:                  'Beispiel-Icons',
    exampleImage:                  'Beispiel-Bild',
    exampleImageStorage:           'Beispiel-Image-Storage',
    exampleImageUploadDescription: 'Bild',
    exampleSubmitDescription:      'Senden',
    exampleNameDescription:        'Name',
    exampleKeyDown:                'Beispiel-Tasten-Events',
    exampleKeyDownText:            'Du kannst in diesem Textfeld nicht die "Enter"-Taste benutzen:',
    exampleInputResizableText:     'Das Input-Feld ist vergrößerbar',
    exampleInputNotResizableText:  'Das Input-Feld ist nicht vergrößerbar',
    exampleLabels:                 'Beispiel-Labels',
    exampleLeftRightSplitter:      'Beispiel-Left-Right-Splitter',
    exampleNormalizer:             'Beispiel-Normalizer',
    exampleNotifications:          'Beispiel-Benachrichtigungen',
    exampleNumberFormat:           'Beispiel-Nummernformatierung',
    exampleProgress:               'Beispiel-Fortschrittsbalken',
    exampleRepeat:                 'Beispiel-Repeat',
    examples:                      'Beispiele',
    home:                          'Home',
    iAmScrolled:                   'Ich scrolle mit',
    imprint:                       'Impressum',
    privacy:                       'Datenschutz',
    inventory:                     'Inventar',
    inventoryDetails:              'Inventar-Details',
    inventoryHasChanged:           'Das Inventar wurde geändert: {{result}}',
    itemNotFound:                  'Eintrag nicht gefunden',
    less:                          'Weniger',
    loading:                       {
        setNewPassword:      'Passwort wird gesetzt...',
        passwordReset:       'Passwort wird zurückgesetzt...',
        signup:              'Registrierung wird durchgeführt...',
        calculationSave:     'Speichere Berechnung...',
        changePassword:      'Passwort wird geändert...',
        colorSettings:       'Lade Kunden-Rechner...',
        colorSettingsSave:   'Speichere Kunden-Rechner...',
        configuration:       'Lade Server-Konfiguration...',
        customerDelete:      'Lösche Kunde...',
        customers:           'Lade Kunden...',
        customerSave:        'Speichere Kunde...',
        dashboard:           'Lade Dashboard...',
        default:             'Lade...',
        login:               'Prüfe Zugangsdaten...',
        objectDelete:        'Lösche Objekt...',
        objects:             'Lade Objekte...',
        objectSave:          'Speichere Objekt...',
        settingsSave:        'Speichere Einstellungen...',
        translations:        'Lade Übersetzungen...',
        unitDelete:          'Lösche Einheit...',
        unitSave:            'Speichere Einheit...',
        user:                'Lade Nutzer...',
        userSave:            'Speichere Nutzer...',
        calculationSendMail: 'Sende E-Mail...',
        subscriptionOptions: 'Lade Pakete...',
        fetchCalculation:    'Lade Berechnung...',
        deleteCalculation:   'Lösche Berechnung...',
    },
    login:                         'Login',
    loginError:                    'Der Login ist leider fehlgeschlagen. Bitte überprüfe deine Zugangsdaten und versuchen es erneut.',
    logout:                        'Logout',
    markupTranslationExample:      'Die foo ist ein <strong>{{bar}}</strong>',
    newVersionHintLaterButton:     'Später',
    newVersionHintText:            'Eine neue Version dieser Anwendung ist verfügbar. Bitte führen Sie jetzt ein Update durch.',
    newVersionHintUpdateButton:    'Update',
    rangeCombinerFrom:             'von',
    rangeCombinerShort:            ' - ',
    rangeCombinerTo:               'bis',
    reloadData:                    'Daten neu laden',
    repeatedText:                  'Dieser Text wurde {{iterationCount}} mal wiederholt.',
    screenTooSmallText:            'Ihre aktuelle Browsergröße wird derzeit nicht unterstützt. Bitte vergrößern Sie dieses Fenster.',
    screenTooSmallTitle:           'Ihr Display oder Browserfenster ist zu klein',
    shuffleList:                   'Liste mischen',
    smartUpdater:                  'Smart Updater Debugger',
    smartUpdaterData:              'Daten',
    smartUpdaterLastUpdate:        'Zuletzt aktualisiert',
    smartUpdaterReload:            'Neu laden',
    smartUpdaterReloadAll:         'Alle Daten neu laden',
    smartUpdaterReset:             'Zurücksetzen',
    smartUpdaterResetAll:          'Smart Updater zurücksetzen',
    stagingPasswordPlaceholder:    'Staging-Passwort',
    thousandSeparator:             '.',
    webAppCancel:                  'Abbrechen',
    webAppInstall:                 'Installieren',
    webAppInstallText:             'Sie können diese Anwendung installieren, um sie dauerhaft offline verwenden zu können.',
    webAppOk:                      'OK',
    windowSize:                    'Fenstergröße',
});

export default german;
